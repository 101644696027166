$(function () {
  var globalRelatedTarget;

  var openMenu = function () {
    var $inputDiv = $(this).closest('[data-behavior="action-menu"]');
    $inputDiv.addClass('open');
    $inputDiv.find('.select-options').focus();
  };

  var closeMenu = function (e) {
    var $inputDiv = $(this).closest('[data-behavior="action-menu"]');
    var relatedTarget = e.relatedTarget || globalRelatedTarget;
    var menuContainsTarget = relatedTarget && $.contains($inputDiv[0], relatedTarget);
    globalRelatedTarget = null;

    if (!menuContainsTarget) {
      $inputDiv.removeClass('open');
    }
  };

  var actionClicked = function () {
    var $inputDiv = $(this).closest('[data-behavior="action-menu"]');
    $inputDiv.removeClass('open');
  };

  var setGlobalRelatedTarget = function (e) {
    globalRelatedTarget = e.target;
  };

  $(document).on('click', '[data-behavior="action-menu"] .display-selected', openMenu);
  $(document).on('blur', '[data-behavior="action-menu"] .select-options', closeMenu);
  $(document).on('click', '[data-behavior="action-menu"] .select-options li a', actionClicked);
  $(document).on('mousedown', '[data-behavior="action-menu"] .select-options li a', setGlobalRelatedTarget);
});

