$(function () {
  $(document).on('click', '[data-behavior="radio"] .radio-options li', function (e) {
    var $button = $(this),
      $hiddenInput = $button.find('input');

    $hiddenInput.prop('checked', true).change();
    $button.addClass('selected').siblings().removeClass('selected');
  });
});

